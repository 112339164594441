.polaroid-container{
    border: 1px solid #000;
    position: relative;
    width: 210px;
    height: 270px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.polaroid-container div{
    margin-top: -30px;
    width: 180px;
    height: 180px;
    background-color: #000;
}

.polaroid-container img{
    margin-top: -30px;
    width: 180px;
    height: 180px;
    background-color: #000;
    object-fit: cover;
}

.polaroid-container input{
    display: none;
}

.polaroid-container label{
    display: block;
    color: #fff;
    width: 100%;
    height: 100%;
}

.btn-clear{
    cursor: pointer;
    border-radius: 1em;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    padding: 1em;
    background-color: #f00;
    border: none;
    position: absolute;
    bottom: 0;
    margin: 1em;
    transition: all 0.3s ease-in-out;
}

.btn-clear:hover{
    transform: scale(1.1);
}