body {
  margin: 0;
  font-family: serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0F3832;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media print{
  .polaroid-main-header, .main-footer{
    display: none !important;
  }
  @page{
    margin: 1cm 0.3cm 1cm 1.3cm ;
  }
  
  .polaroid-main{
    width: 100%!important;
    background-color: #fff!important;
    display: block!important;
    /* justify-content: start!important;
    align-items: start!important; */
  }


  /* .polaroid-list{
    display: grid!important;
    grid-template-columns: 0.5fr 0.5fr!important;
    gap:0!important;
  } */

  .polaroid-list{
    width: 100%!important;
    display: block!important;
    flex-wrap: nowrap!important;
  }

  .polaroid-container{
    box-sizing: border-box!important;
    display: inline-flex!important;
    margin: 0!important;
  }

  .polaroid-list .polaroid-container{
    width: 7cm!important;
    height: 9cm!important;
  }
  .polaroid-list .polaroid-container img{
    margin-top: -1cm;
    width: 6cm!important;
    height: 6cm!important;
  }
  /* .polaroid-list .polaroid-container:nth-child(5){
    transform: rotate(90deg) translateY(-1cm);
  }
  .polaroid-list .polaroid-container:nth-child(6){
    transform: rotate(90deg) translateY(-1.5cm);
  } */
}